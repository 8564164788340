(function($) {

  // Posizione form
    var asideFormPosition = function() {
      if (window.matchMedia('(max-width: 991px)').matches) {
          $('#asideForm').detach().appendTo('.form-placeholder-mobile');
      } else {
          $('#asideForm').detach().appendTo('.form-placeholder-desktop');
      }
    };

$(document).ready(function(){

  asideFormPosition();
	//Detect Browser

	var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
	var isFirefox = typeof InstallTrigger !== 'undefined';   // Firefox 1.0+
	var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
	    // At least Safari 3+: "[object HTMLElementConstructor]"
	var isChrome = !!window.chrome && !isOpera;              // Chrome 1+
	var isIE = /*@cc_on!@*/false || !!document.documentMode;   // At least IE6

	if (isFirefox) {
		$('.nav-secondary').addClass('ff');

	} else {
		$('.nav-secondary').addClass('else');
	}

	if (isSafari) {
		$('.nav-primary ul li a, .nav-secondary ul li a, .menu-mobile ul li a').addClass('bomba');
	} else {

	}




	$('.blulab-slider').css('visibility','visible').hide().fadeIn(100);



	$('.slides').responsiveSlides({
                "timeout": 4000,          // Integer: Time between slide transitions, in milliseconds
    });


	$('.toggle-lang').on('click', function(){
		$('.lang-menu').slideToggle();
		// console.log('click');
	});

        $('.product-title-content').on('click', function(){
		$(this).next('.product-detail-content').slideToggle();

                // $('.product-detail-content').slideToggle();
		// console.log('click');
	});

	$('.navbar-toggle').on('click', function(){
		$('.menu-mobile').slideToggle();
	});

	$('header .nav-primary .menu-item-has-children').on('click', function(e){
		e.stopPropagation();
    $('header .sub-menu').hide();
		$(this).find('.sub-menu').fadeToggle();
	});

	$('header .menu-mobile .blu .menu-item-has-children').on('click', function() {
    $('header .sub-menu').hide();
		$(this).find('.sub-menu').slideToggle();
	});

	$('.content-slide').addClass('container');

	$('.mob-menu').on('click', function(){
		$(this).find('ul').slideToggle();
	});


	function posSlider() {
		$('.slides li').each(function(){
			var largTutt = $(this).width();
			var largCont = $(this).find('.content-slide').innerWidth();
			var largOk = (largTutt - largCont) / 2;

			$(this).find('.content-slide').css({'right': largOk});

		});
	}



	function boxStabile() {
		$('.lancio-single').each(function(){
			var WW = ($(this).innerWidth() - $(this).find('.box-lg').width() ) / 2;
			var HH = ($(this).innerHeight() - $(this).find('.box-lg').outerHeight() ) / 2;
			$(this).find('.box-lg').css({'left': WW, 'top': HH });

		});
	}

	$('.sticaz').stick_in_parent();
	$('.sticaz_h').stick_in_parent({'offset_top':150});

	$('.sticaz, .sticaz_h')
		.on('sticky_kit:bottom', function(e) {
		$(this).parent().css('position', 'static');
	})
		.on('sticky_kit:unbottom', function(e) {
		$(this).parent().css('position', 'relative');
	});


	function boxHomePag() {
		$('.pagine-single').each(function(){
			var HHP = $(this).find('.immagine.lg').height() - 20;
			$(this).find('.testo.lg').innerHeight(HHP);
		});
	}


	//Gestisce lo slider di destinazioni

	function SliderDest() {
		var WWW = $(window).width();
		if(WWW >= 992) {
			$('body.page-template-tpl_destinazioni').find('.blulab-slider').addClass('move-me');
			$('.move-me li').each(function(){
				var HHHOK = ($('.content-slide').height() - $('hgroup').height()) / 2;

				$('hgroup').css({'bottom':HHHOK});

				// console.log(HHHOK);
				// console.log($('.content-slide').outerHeight());
				// console.log($('hgroup').height());

			});
		} else {
			$('body.page-template-tpl_destinazioni').find('.blulab-slider').removeClass('move-me');
			$('hgroup').css({'bottom':30});
		}
	}

	if ($('body').hasClass('page-template-tpl_download')) {
		$('.nav-second').find('.download').addClass('current_page_item');
	}

	if ($('body').hasClass('page-template-tpl_dove')) {
		$('.nav-second').find('.where').addClass('current_page_item');
	}

	if ($('body').hasClass('single-linee-destinazioni')) {
		$('.nav-primary').find('.linee').addClass('current_page_item');
	}




	// FAQ

	$('.faq-single-cont').each(function(){
		$(this).find('.bottone').on('click', function(){
			$(this).parent().toggleClass('nope');
			$(this).parent().find('.risposta').slideToggle(300);
			$(this).parent().find('.colore h3').toggle(1);
			$(this).parent().find('.colore h4').toggle(1);
		});
	});

	function FAQ() {
		var WWW = $(window).width();
		if(WWW <= 992) {

			$('.faq-single-cont').each(function(){
				$(this).find('.bottone-sm').height($(this).find('.domanda h4').height() + 20);
			});

			} else {
				$(this).find('.bottone-sm').css({'height':'auto'});
			}
	}







	boxStabile();
	boxHomePag();
	new SliderDest();
	FAQ();

	$(window).on('load', function(){
		posSlider();
		boxStabile();
		new SliderDest();
		boxHomePag();
	});


	$(window).on('resize', function(){
		posSlider();
		boxStabile();
    asideFormPosition();
		new SliderDest();
		boxHomePag();
		FAQ();
	});


});

})(jQuery); // Fully reference $ after this point.
